<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class=" p-5">
          <h3 class="font-weight-bold mb-4" style='font-size: 30px'>Nuevo Cliente</h3>
          <div>
            <h3 class="font-weight-bold mb-2" style='font-size: 20px'>General</h3>
            <p class="mb-2" style='font-size: 13px'>La información de contacto de tus clientes aparecerá en las facturas y en sus perfiles. También puedes permitir que tus clientes inicien sesión para hacer un seguimiento de las facturas que les envías marcando la casilla de abajo.</p>
          </div>
          <hr style='height: 1px; background: var(--dark);' class='w-100 mb-3' />
          <div class='d-flex flex-column' style='gap: 1rem;'>
            <div class="row">
              <article class="col-12 col-md-6 pb-3 pb-md-0">
                <label>Nombre *</label>
                <b-form-input aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Nombre"
                  required trim></b-form-input>
              </article>
              <article class="col-12 col-md-6">
                <label>Correo electrónico</label>
                <b-form-input :required="!statusCompra" aria-describedby="input-live-help input-live-feedback"
                  placeholder="Ingrese Correo electrónico" trim></b-form-input>
                <p v-if='statusCompra' class='m-0 fs-2'>campo requerido</p>
              </article>
            </div>
            <div class="row">
              <article class="col-12 col-md-6 pb-3 pb-md-0">
                <label>Teléfono</label>
                <b-form-input aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Teléfono"
                  trim></b-form-input>
              </article>
              <article class="col-12 col-md-6">
                <label>Página web</label>
                <b-form-input aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Página web"
                  trim></b-form-input>
              </article>
            </div>
            <div class="row">
              <article class="col-12 col-md-6 pb-3 pb-md-0">
                <label>Referencia</label>
                <b-form-input aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Referencia"
                  trim></b-form-input>
              </article>
              <article class="col-12 col-md-6 d-flex align-items-end">
                <b-button class='bg-dark border-0' v-b-tooltip.hover
                  title="Envia una invitación a este usuario para iniciar sesión en el portal del cliente">
                  <b-form-checkbox size="md" v-model="statusCompra">
                    Invitar al Portal del Cliente
                  </b-form-checkbox>
                </b-button>
              </article>
            </div>
          </div>
          <div class='pt-4'>
            <h3 class="font-weight-bold mb-2" style='font-size: 20px'>Facturación</h3>
            <p class="mb-2" style='font-size: 13px'>El número de identificación fiscal aparece en cada factura que se
              emite al cliente. La moneda seleccionada se convierte en la moneda predeterminada para este cliente.</p>
          </div>
          <hr style='height: 1px; background: var(--dark);' class='w-100 mb-3' />
          <div class="row">
            <article class="col-12 col-md-6">
              <label>CIF/NIF*</label>
              <b-form-input aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese CIF/NIF"
                trim></b-form-input>
            </article>
          </div>
          <div class='pt-4'>
            <h3 class="font-weight-bold mb-2" style='font-size: 20px'>Dirección</h3>
            <p class="mb-2" style='font-size: 13px'>La dirección es obligatoria para las facturas, por lo que debe
              agregar los detalles de la dirección de facturación de su cliente.</p>
          </div>
          <hr style='height: 1px; background: var(--dark);' class='w-100 mb-3' />
          <div class='d-flex flex-column' style='gap: 1.5rem;'>
            <div class="row">
              <article class="col-12">
                <label>Dirección</label>
                <b-form-textarea rows="4" class='p-3' placeholder="Descripción"></b-form-textarea>
              </article>
            </div>
            <div class="row">
              <article class="col-12 col-md-6 pb-3 pb-md-0">
                <label>Barrio/Ciudad</label>
                <b-form-input aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Barrio/Ciudad"
                  trim></b-form-input>
              </article>
              <article class="col-12 col-md-6">
                <label>Postal/Código postal</label>
                <b-form-input aria-describedby="input-live-help input-live-feedback"
                  placeholder="Ingrese Postal/Código postal" trim></b-form-input>
              </article>
            </div>
            <div class="row">
              <article class="col-12 col-md-6 pb-3 pb-md-0">
                <label>Provincia/Estado</label>
                <b-form-input aria-describedby="input-live-help input-live-feedback"
                  placeholder="Ingrese Provincia/Estado" trim></b-form-input>
              </article>
              <article class="col-12 col-md-6">
                <label>País</label>
                <b-form-input aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese País"
                  trim></b-form-input>
              </article>
            </div>
          </div>
          <div class='d-flex pt-4 justify-content-end'>
            <vs-button size="large" dark shadow @click.prevent="$router.push('Facturas')">Cancelar</vs-button>
            <vs-button type='button' @click.prevent='' size="large">Guardar</vs-button>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
        statusCompra: false,
      }
    }
  }
</script>